<template>
  <div class="content">
    
    <div class="image-container">
      <img class="helmet-image" :alt="helmetDetails.helmetNumber" :src="`${helmetDetails.imageFile}`" />
    </div>

    <div class="button-container">
      <button class="love-it-button" @click="getHelmet(0)">Previous</button>
      <button class="shelve-it-button" @click="getHelmet(1)">Next</button>
    </div>
  </div>
</template>

<script>

import HelmetService from "../services/HelmetService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import { ASelect, ASelectOption, AButton, Table, Pagination, message } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { UploadOutlined } from '@ant-design/icons-vue';
import Datepicker from 'vuejs-datepicker';


const tableColumns = [];
const tableData = [];
const fileList = ([]);
let file = ({});
const checked = (false);
let yearValue = ('');

export default {
  props: {
    helmetId: {
      type: String,
      required: true
    }
  },
  components: {
		Modal,
		BaseButton,
		BaseInput,
		VueAutosuggest,
		UploadOutlined,
		Datepicker,
  },
  data() {
    return {
      helmetToAdd: {},
      modal2Helmets: false,
      helmetDetails: null,


      fileList,
      checked,
	  yearValue,

      showGeneratedImageDialog: false, // Add this property to control the visibility of the generated image dialog
      generatedImageUrl: '',
	  imageGenerate: {},
      prompt: '',
      uploadGeneratedImage: {},
      showProgressBar: false,
	  userPermissionDialog:false,
      loadingText: 'Please wait . . .',
      percenatage: 0,
      imgSrc: '',      

    };
  },
  methods: {

    

	
	handleSwitchChange(lifAnswerId, checked) {
	    this.answers.forEach(function(answer) {
	    	if (answer.questionType == '2') {
		    	if (answer.lifAnswerId == lifAnswerId) {
					answer.answerb = checked;
					if ( checked) {
						answer.answer = "Yes";
					} else {
						answer.answer = "No";
					}
				}
	    	}
		});
	},

 	handlePermissionDialogClose() {
      this.userPermissionDialog = false;
    },

   handleChange(info) {
      if (info.file.status === 'error' || info.file.status === 'done') {
        message.success(`upload image now`);
        file = info.file;
      }
    },
    
      async handleUpload() {
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      const res = await HelmetService.uploadImage(formData, this.teamDetails.teamId);
      if (res.status === 200) {
        message.success(
            'Image uploaded successfully',
            10,
        );
      }
    },
    async generateImage() {
      if (!this.prompt) {
       alert('Please enter a prompt');
        return;
      }
      this.showProgressBar = true;
      this.imageGenerate.prompt = this.prompt;
      this.imageGenerate.size = '1024x1024';
      this.imageGenerate.num_images = 1;
      const jsonData = JSON.stringify(this.imageGenerate);
      console.log(jsonData);
      this.percenatage = 50;        
    
          try {
        const res = await HelmetService.generateImage(jsonData);
        this.percentage = 100;
        this.showProgressBar = false;
        console.log("res ", res);
        if (res && res.data.data && res.data.data.length > 0) {
          // Extract the URL of the generated image
          const generatedImageUrl = res.data.data[0].url;
          // Set the generated image URL to display
          this.generatedImageUrl = generatedImageUrl;
          console.log(generatedImageUrl);
          // Show the generated image dialog
          this.showGeneratedImageDialog = true;
        } else {
          // Handle the case when no image URL is returned
          console.error("No generated image URL found in the response");
        }
      } catch (error) {
        this.showProgressBar = false;
        this.percentage = 0;
        this.userPermissionDialog = true;
        console.error("An error occurred while generating the image", error);
        if (error.code === 'ERR_BAD_REQUEST') {
     	}
      }        
	},
    
    async saveGeneratedImage() {
      this.showProgressBar = true;
      this.uploadGeneratedImage.url = this.generatedImageUrl;
      const jsonData = JSON.stringify(this.uploadGeneratedImage);
      console.log(jsonData);
      const res = await HelmetService.uploadAutoGeneratedImage(jsonData, this.teamDetails.teamId);
      console.log(res);
      await this.getHelmetDetails();
      this.showProgressBar = false;
      this.showGeneratedImageDialog = false;
    },
    async cancelGeneratedImage() {
      // Clear the generated image URL
      this.generatedImageUrl = '';
      // Close the modal dialog
      this.showGeneratedImageDialog = false;
    },        
    async handleAddSubmitted() {
      this.modal2Helmets = false;

      const jsonData = JSON.stringify(this.helmetToAdd);
      console.log(jsonData);
      
      const res = await HelmetService.addHelmet(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},

    async updateHelmet() {

      const jsonData = JSON.stringify(this.helmetDetails);
      const res = await HelmetService.update(jsonData);

      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalHelmets = false;
//        this.getAllHelmets();
      }
    },

	async getHelmet(nextOrPreviousValue) {

		const findHelmetIdData = {
			helmetId : this.helmetId,
			listType : 1,
			nextOrPrevious : nextOrPreviousValue
    	};

        const jsonData = JSON.stringify(findHelmetIdData);
        console.log(jsonData);

      	try {
			let response = await HelmetService.findHelmetId(jsonData);
			this.helmetId = response.data;
	
			this.getHelmetDetails();

	      } catch (error) {
	        console.error('Error fetching helmet details:', error);
	      }
	},

    async getHelmetDetails() {
      try {
			let response = await HelmetService.get(this.helmetId);
			this.helmetDetails = response.data;

			let hasAnswers = 0;


 			// fetching image
      		const imagePath = this.helmetDetails.imageFile;
      		const parts = imagePath.split("/");
      		const imageName = parts[parts.length - 1];
      		this.imgSrc = '/images/'+imageName;

			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}


	    
      } catch (error) {
        console.error('Error fetching helmet details:', error);
      }
    },


	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
    this.getHelmetDetails();
  },
  computed: {
  },
  
};
</script>
<style>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.brand {
  margin-bottom: 10px;
}

.brand img {
  height: 45px;
}

.image-container {
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.helmet-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 35px;
  object-fit: contain;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.love-it-button, .shelve-it-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.love-it-button {
  background: linear-gradient(to bottom left, #00f2c3, #1890FF);
}

.shelve-it-button {
  background: linear-gradient(to bottom left, #e8e8e8, #8c8c8c, #1890FF);
}
</style>
