import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import Helmets from  '@/pages/Helmets.vue';
import HelmetDetail from  '@/pages/HelmetDetail.vue';
import CreateHelmets from  '@/pages/CreateHelmets.vue';
import Users from  '@/pages/Users.vue';
import UserDetail from  '@/pages/UserDetail.vue';
import Roles from  '@/pages/Roles.vue';
import RoleDetail from  '@/pages/RoleDetail.vue';
import UserRoles from  '@/pages/UserRoles.vue';
import UserRoleDetail from  '@/pages/UserRoleDetail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
		redirect: '/sign-in',
  },
  {
    path: "/sign-in",
	name: 'Sign-In',
    component: () => import("../views/Sign-In.vue"),
  },
  {
    path: "/sign-up",
	name: 'Sign-Up',
    component: () => import("../views/Sign-Up.vue"),
  },
  {
    path: "/pricing",
    name: "PricingView",
    component: () => import("../views/PricingView.vue"),
  },
  {
    path: "/arts-gallery",
    name: "ArtsGalleryView",
    component: () => import("../views/ArtsGalleryView.vue"),
  },
  {
    path: "/checkout/:id",
    name: "CheckoutView",
    component: () => import("../views/CheckoutView.vue"),
  },
  {
    path: "/stripe-checkout",
    name: "StripeCheckoutView",
    component: () => import("../views/StripeCheckoutView.vue"),
  },
	{
		path: '/helmets',
		name: 'Helmets',
		layout: DefaultLayout,
		component: Helmets,
	},
	{
	    path: '/helmet/:helmetId', 
	    name: 'HelmetDetail',
		layout: DefaultLayout,
	    component: HelmetDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/create',
		name: 'CreateHelmets',
		layout: DefaultLayout,
		component: CreateHelmets,
	},
	{
		path: '/users',
		name: 'Users',
		layout: DefaultLayout,
		component: Users,
	},
	{
	    path: '/user/:userId', 
	    name: 'UserDetail',
		layout: DefaultLayout,
	    component: UserDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/roles',
		name: 'Roles',
		layout: DefaultLayout,
		component: Roles,
	},
	{
	    path: '/role/:roleId', 
	    name: 'RoleDetail',
		layout: DefaultLayout,
	    component: RoleDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/userRoles',
		name: 'UserRoles',
		layout: DefaultLayout,
		component: UserRoles,
	},
	{
	    path: '/userRole/:userRoleId', 
	    name: 'UserRoleDetail',
		layout: DefaultLayout,
	    component: UserRoleDetail,
	    props: true // Pass route params as props to the component
  	},
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
